import React from 'react'
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

import storeImg from '../../../images/store.png';


function Store() {
  return (
    <div className='main mtp-2'>
      <div className="container">
        <h2 className='seclob-h1'>Store</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
            <Grid item xs={3} sm={3} md={2} >
              <div className='maintance-grid'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='maintance-linear'>
                  <p>Painter</p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={2} >
              <div className='maintance-grid'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='maintance-linear'>
                  <p>Painter</p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={2} >
              <div className='maintance-grid'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='maintance-linear'>
                  <p>Painter</p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={2} >
              <div className='maintance-grid'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='maintance-linear'>
                  <p>Painter</p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={2} className="md-hide">
              <div className='maintance-grid'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='maintance-linear'>
                  <p>Painter</p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={2} className="md-hide">
              <div className='maintance-grid'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='maintance-linear'>
                  <p>Painter</p>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default Store