import React from "react";
import { Formik, Form } from "formik";

const FormikForm = props => {
	const { innerRef, initialValues, onSubmit, validator, onFormChange } = props;
	return (
		<React.Fragment>
			<Formik
				enableReinitialize={true}
				innerRef={innerRef}
				initialValues={initialValues || {}}
				onSubmit={onSubmit}
				validationSchema={validator}
				validateOnBlur={true}
				validateOnChange={true}
			>
				{({ handleChange, handleBlur, handleSubmit, resetForm }) => (
					<Form
						onSubmit={handleSubmit}
						onChange={e => {
							handleChange(e);
							if (onFormChange) onFormChange(e);
						}}
						onBlur={handleBlur}
						onReset={resetForm}
					>
						{props.children}
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};

export default FormikForm;
