import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import {Route, Routes} from "react-router-dom";
import Toast from "./components/Toast/Toast";
import { useSelector } from "react-redux";

import Home from './components/customer/Home';
import Offer from './components/customer/Offer';
import Login from './components/customer/Login';
import PartnerRegister from './components/customer/PartnerRegister';
import Services from "./components/customer/Services";
import Vechicle from "./components/customer/Vechicle";

import AboutUS from "./components/customer/AboutUS";
import Terms from "./components/customer/Terms";
import Privacy from "./components/customer/Privacy";
import Cancellation from "./components/customer/Cancellation";
import FAQ from "./components/customer/FAQ";
import ServicesSingle from "./components/customer/ServicesSingle";
import Register from "./components/customer/Register";
import GoogleRegister from "./components/customer/GoogleRegister";


function App() {
  const ToastState = useSelector((state) => state.toast);

  return (
    <>
      <Toast
        open={ToastState.open}
        message={ToastState.message}
        severity={ToastState?.severity}
      />
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/offer" element={ <Offer /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/register" element={ <Register /> } />
        <Route path="/register/:cred" element={ <GoogleRegister /> } />
        <Route path="/partner/register" element={ <PartnerRegister /> } />

        <Route path="/services" element={ <Services /> } />
        <Route path="/:district/:location/services" element={ <Services /> } />
        <Route path="/:district/:service/:id" element={ <ServicesSingle /> } />

        <Route path="/:district/:location/vechicle-services" element={ <Vechicle /> } />


        
        <Route path="/about-us" element={ <AboutUS /> } />
        <Route path="/terms" element={ <Terms /> } />
        <Route path="/privacypolicy" element={ <Privacy /> } />
        <Route path="/cancellationpolicy" element={ <Cancellation /> } />
        <Route path="/faq" element={ <FAQ /> } />





        {/* <Route path="/admin" element={ <Admin /> } /> */}

        
      </Routes>
    </>
  );
}

export default App;
