import React, {useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';

import CustomLink from './CustomLink';
import { Axios, CUSTOMERGOOGLEVERTFY, CUSTOMERLOGIN } from '../../utils/apiUtils';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";

import FormikForm from '../../components/FormikForm/FormikForm';
import  FormikTextField  from '../../components/FormikTextField/FormikTextField';
import { Regx } from '../../utils/Regx';


import logo from '../../images/full_logo_web.png';
import logoBack from '../../images/seclob_back2.png';
import './Login.css';


function Login() {
  const win = window.localStorage;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [userKey, setUserKey] = useState('');

  
  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  function handleCallBackResponse(resp){
    callVerifyToken(resp.credential);
  }

  const google = window.google;

  React.useEffect( () => {
    if(win.getItem('userId')) setUserId(win.getItem('userId'));
    if(win.getItem('userName')) setUserName(win.getItem('userName'));
    if(win.getItem('userKey')) setUserKey(win.getItem('userKey'));
    
    if(win.getItem('userId') > 0) {
      navigate('/');
    } {
      google.accounts.id.initialize({
        client_id: "777399470055-5h8mrrkbhb4gi1pietvhnbg7ma3qhmbt.apps.googleusercontent.com",
        callback: handleCallBackResponse
      });

      google.accounts.id.renderButton(
        document.getElementById("googleSignInDiv"),
        { theme: "outline", size: "large", width:'100%'}
      );
    }

  }, []);

  const callVerifyToken = async (userCredential) => {
    Axios.post(`${CUSTOMERGOOGLEVERTFY}/${userCredential}`, {}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
        navigate('/register/' + userCredential);
      } else {
        let user = response.data.user;
        win.setItem('userId', user.id);
        win.setItem('userName', user.name);
        win.setItem('userKey', user.user_key);

        setUserId(user.id);
        setUserName(user.name);
        setUserKey(user.user_key);

        navigate('/');
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const Validator = Yup.object().shape({
    username: Yup.string()
      .required("Username Required.")
      .matches(Regx.emailOrPhone, "Invalid Username."),
    password: Yup.string()
      .required("Password Required.")
      .min(6, "Password too Short.")
  });

  const handleSubmit = async (data) => {
    await callLoginAPI(data);
  }

  const callLoginAPI = async (inputPayload) => {
    console.log('Enter Here');
    Axios.post(CUSTOMERLOGIN, inputPayload).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
        navigate('/register/' + inputPayload);
      } else {
        let user = response.data.user;
        win.setItem('userId', user.id);
        win.setItem('userName', user.name);
        win.setItem('userKey', user.user_key);

        setUserId(user.id);
        setUserName(user.name);
        setUserKey(user.user_key);

        navigate('/');
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + logoBack})`
  };

  return (
    <div className='main login-image'  style={myStyle}>
      <div className="container-sec">
        <div className="login">
          <Link to="/">
            <img src={logo} className="" alt="seclob-logo" />
          </Link>
          <div className="card-main">
            <div className="card">
              <div className="card-header">
                <h3>Login/Register</h3>
              </div>
              <div className='card-body' >
                <FormikForm validator={Validator}  onSubmit={handleSubmit}>
                  <FormikTextField 
                    name="username"
                    className="form-input block input-login mt-2"
                    label="Enter your email or Phone Number"
                    size="medium"
                  />
                  <FormikTextField 
                    name="password"
                    className="form-input block input-login mt-2"
                    label="Enter your Password"
                    size="medium"
                  />
                  <button type="submit"  className='btn btn-login btn-block mt-2'>
                    Sign In
                  </button>
                  <a className='login-a right'>Forget Password?</a>
                  <br/>
                  <br/>
                  <div id="googleSignInDiv" width="100%">

                  </div>
                  <span className='login-span'>
                    <p className='inline-block login-p'>Don’t have an account?<CustomLink to="/register" className='login-a'>Create one</CustomLink></p>
                  </span>
                </FormikForm>
              </div>
            </div>
          </div>

        </div>
        <div className="login-footer" >
          <p className='login-footer-p'>Copyright 2022 Seclob Technologies Pvt.Ltd. All Rights Reserved | Designed & Developed By Seclob Technologies</p>
        </div>
      </div>
    </div>
  )
}

export default Login