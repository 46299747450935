import { LOC_DROPDOWN_CONTEXT } from '../context';

const LocDropdownInintialState = {
    open: false
};

export const LocDropdownReducer = (state = LocDropdownInintialState, action) => {
    switch (action.type) {
        case LOC_DROPDOWN_CONTEXT.LOC_DROPDOWN_SHOW:
            return {
                open: true
            };

        case LOC_DROPDOWN_CONTEXT.LOC_DROPDOWN_HIDE:
            return { open: false };

        default:
            return { open: false };
    }
};