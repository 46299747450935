import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import CustomLink from './CustomLink';
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';
import { useDispatch } from 'react-redux';

import './Footer.css';
import blackLogo from '../../images/logo_black.png';

import footerMobileOne from '../../images/footer/one.png';
import footerMobileTwo from '../../images/footer/two.png';
import footerMobileThree from '../../images/footer/three.png';
import footerMobileFour from '../../images/footer/four.png';


import seclobTwitter from '../../images/twitter.png';
import seclobYoutube from '../../images/youtube.png';
import seclobLinkedIn from '../../images/linkedin.png';
import seclobFacebook from '../../images/facebook.png';
import seclobInstagram from '../../images/insta.png';


import { Axios, FOOTERLIST_URL } from '../../utils/apiUtils';


function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lists, setLists] = useState([]);
  const [lists2, setLists2] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(FOOTERLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.footerServices);
        setLists2(response.data.footerWhySeclob);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }


  return (
    <>
      <div className='main mtp-2 md-hide footer-why'>
        <div className="container">
          <h3 className='seclob-h1'>Why Seclob?</h3>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
            { isSet === true ? 
                lists2.map( (resp) => {
                  return(
                    <Grid item xs={6} sm={4} md={2}  key={resp.name}>
                      <div className='why-grid'>
                        <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt="why_seclob" />
                        <h4 className='f-h5 h-whyseclob'>{resp.name}</h4>
                      </div>
                    </Grid>
                  )
                }) 
                :
                <></>
              }
            </Grid>
          </Box>
        </div>
      </div>
      <div className='footer mtp-2 md-hide'>
        <div className="">
          <div className="links" >
            <Link to="/about-us" className="link" ><h5 className='f-h5'>About us</h5></Link>
            <div className="border-right"></div>
            <Link to="/terms" className="link" ><h5 className='f-h5'>Terms & Conditions</h5></Link>
            <div className="border-right"></div>
            <Link to="/privacypolicy" className="link" ><h5 className='f-h5'>Privacy Policy</h5></Link>
            <div className="border-right"></div>
            <Link to="/cancellationpolicy" className="link" ><h5 className='f-h5'>Cancellation Policy</h5></Link>
            <div className="border-right"></div>
            <Link to="/faq" className="link" ><h5 className='f-h5'>FAQ</h5></Link>
            <div className="border-right"></div>
            <a className="link" href="#"><h5 className='f-h5'>Blog</h5></a>
            <div className="border-right"></div>
            <a className="link" href="#"><h5 className='f-h5'>Contact Us</h5></a>
          </div>
          <br/>
          <p><b>Some of our services that will prove useful to you on a day-to-day basis are:</b></p>
          <br/>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
            { isSet === true ? 
                lists.map( (resp) => {
                  return(
                    <Grid item xs={12} sm={6} md={6} lg={4}  className="footer-grid" key={resp.name}>
                      <div className='footer-grid-items'>
                        <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image2} alt="footer" className='f-image' />
                        <h6 className='f-h5'>{resp.name}</h6>
                        <p className='f-p'>{resp.sub_heading}</p>
                      </div>
                    </Grid>
                  )
                }) 
                :
                <></>
              }
            </Grid>
          </Box>
          <br />
          <br />
          <p style={{color:'#727272',fontWeight:'600'}}>Elevate your daily experiences with our wide-ranging suite of services, each thoughtfully designed to enhance your convenience and simplify life's complexities. We understand that modern life can be demanding, which is why we're dedicated to providing solutions that cater to your various needs.Seclob brings a world of services to your fingertips, ensuring that you can access them with ease. From tasks and maintenance to appointments and more, we've got you covered. You don't need to worry about navigating the intricacies of daily life; we're your trusted partner in simplifying it. No matter where you are or what you require, consider us your ultimate destination for a hassle-free, comprehensive approach to convenience.we are your one-stop solution for a seamless experience.</p>
          <br/>
          <br/>
          <center>
            <img src={blackLogo} alt="seclob-footer-logo" style={{maxWidth:'300px',width:'100%', height:'auto'}} />
          </center>
          <center>
            <p className="width-40">Seclob is your user-friendly, one-stop solution that puts everything you need right at your fingertips, making life easier and hassle-free, no matter where you are.</p>
          </center>
          <center>
            <div className='footer-social-div'>
              <span className='footer-social-span'>Follow us On </span>
              <span>
                <a href="https://www.instagram.com/seclob_official" target='_blank' alt="instagram page" ><img className='footer-social-icons' src={seclobInstagram} /></a>
                <a href="https://www.facebook.com/seclobtechnologies" target='_blank' alt="facebook page"><img className='footer-social-icons' src={seclobFacebook} /></a>
                <a href="https://www.linkedin.com/company/seclobtechnologies" target='_blank' alt="linkedin page"><img className='footer-social-icons' src={seclobLinkedIn} /></a>
                <a href="https://twitter.com/home" target='_blank' alt="twitter page"><img className='footer-social-icons' src={seclobTwitter} /></a>
                <a href="https://www.youtube.com/@Seclob" target='_blank' alt="youtube channel"><img className='footer-social-icons' src={seclobYoutube} /></a>
              </span>
            </div>
          </center>
        </div>
      </div>
      <div className="footer-back md-hide">
        <p>Copyright 2022 Seclob Technologies Pvt.Ltd. All Rights Reserved | Designed & Developed By Seclob Technologies</p>
      </div>
      <div className='for-footer md-show' ></div>
      <div className='md-show footer-mobile mtp-3'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
            <Grid item xs={3} sm={3} md={3}>
              <div className="footer-mobile-items">
                <CustomLink to="/" className='navbar-link' >
                  <img src={footerMobileOne} />
                  Home
                </CustomLink>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <div className="footer-mobile-items">
                <CustomLink to="/referal" className='navbar-link' >
                  <img src={footerMobileTwo} />
                  Invite & Earn
                </CustomLink>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <div className="footer-mobile-items">
                <CustomLink to="/free-listing" className='navbar-link' >
                  <img src={footerMobileThree} />
                  Free Listing
                </CustomLink>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <div className="footer-mobile-items">
                <CustomLink to="/profile" className='navbar-link' >
                  <img src={footerMobileFour} />
                  Account
                </CustomLink>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  )
}

export default Footer