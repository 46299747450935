import React from 'react'
import { Link } from "react-router-dom";

import logo from '../../images/seclob_web.png';
import logoBack from '../../images/seclob_back2.png';
import './Login.css';

function PartnerRegister() {

  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + logoBack})`
  };

  return (
    <div className='main login-image'  style={myStyle}>
      <div className="container-sec">
        <div className="login">
          <Link to="/">
            <img src={logo} className="" alt="seclob-logo" />
          </Link>
          <div className="card-main">
            <div className="card">
              <div className="card-header">
                <h3>Register as our Partner</h3>
              </div>
              <div className='card-body' >
                <input type="text" placeholder='Enter User Name' id="prname" className="form-input block" />
                <input type="text" placeholder='Enter Company Name' id="prcname" className="form-input block" />
                <input type="number" placeholder='Enter Mobile Number' id="prmobile" className="form-input block" />
                <input type="email" placeholder='Enter Email Address' id="premail" className="form-input block" />
                <input type="text" placeholder='Enter your Company Location' id="praddress" className="form-input block" />
                <select  id="prservices" className="form-input block" >
                  <option value="">Select Your Service</option>
                  <option value="Services">Services</option>
                  <option value="Enquiry">Enquiry</option>
                  <option value="Eats">Eats</option>
                  <option value="Rooms">Rooms</option>
                  <option value="Doctor Booking">Doctor Booking</option>
                  <option value="Lawyer Booking">Lawyer Booking</option>
                  <option value="Cabs">Cabs</option>
                  <option value="Store">Store</option>
                </select>
                <button className='btn btn-primary btn-block' id="loginMobileBtn">Continue</button>
              </div>
            </div>
          </div>

        </div>
        <div className="login-footer" >
          <p className='login-footer-p'>Copyright 2022 Seclob Technologies Pvt.Ltd. All Rights Reserved. <br/> Designed & Developed by ERE Business Solutions</p>
        </div>
      </div>
    </div>
  )
}

export default PartnerRegister