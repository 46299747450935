import React, { useState } from 'react';
import { Grid, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';
import { useDispatch } from 'react-redux';
import { Axios, LOCATIONFETCH_URL, SERVICEBOOKING_URL, SERVICESINGLESEO_URL, SERVICESINGLE_URL } from '../../utils/apiUtils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {decode as base64_decode} from 'base-64';
import { Helmet } from 'react-helmet';

import moment from 'moment';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import dayjs from 'dayjs';

import Footer from './Footer'
import Navbar from './Navbar'
import './Services.css'


import servicesBack  from '../../images/service/services-back.png';
import sad1  from '../../images/seclob_ad1.jpg';
import sad2  from '../../images/seclob_ad2.png';
import userRating  from '../../images/rating_user.png';

import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import EditIcon from '@mui/icons-material/Edit';

function ServicesSingle() {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const win = window.localStorage;
  const [locid, setLocId] = useState(0);
  const [locname, setLocName] = useState('');
  const [districtid, setDistrictId] = useState(0);
  const [districtname, setDistrictName] = useState('');

  const [userId, setUserId] = useState(0);

  const [seoData, setSEOData] = useState([]);
  const [isSEOSet, setIsSEOSet] = useState(false);  
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [subCategoryID, setSubCategoryID] = useState(0);
  const [ads, setAds] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [ratingCount, setRatingCount] = useState(0);
  const [serviceDate, setServiceDate] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    if(win.getItem('locid')) setLocId(win.getItem('locid'));
    if(win.getItem('locname')) setLocName(win.getItem('locname'));
    if(win.getItem('districtname')) setDistrictName(win.getItem('districtname'));
    if(win.getItem('districtid')) setDistrictId(win.getItem('districtid'));
    if(win.getItem('userId')) setUserId(win.getItem('userId'));


    const idsArray = id.split("-");
    let subCategoryId = base64_decode(idsArray[1]);
    let locationId = base64_decode(idsArray[2]);

    let seoType = idsArray[0];
    if(win.getItem('locid') !== locationId){
      callLocationFetchAPI(locationId);
    }
    setSubCategoryID(subCategoryId);

    callSEOAPI(subCategoryId, seoType);
    callHomeAPI(subCategoryId);
  }, []);

  const callSEOAPI = async (subCategoryId, seoType) => {
    Axios.post(`${SERVICESINGLESEO_URL}/${subCategoryId}/${seoType}`, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setSEOData(response.data.data[0]);
        setIsSEOSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callLocationFetchAPI = async (locationId) => {
    Axios.post(`${LOCATIONFETCH_URL}/${locationId}`).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        win.setItem('locid', response.data.data.id);
        win.setItem('locname', response.data.data.name);
        win.setItem('districtid', response.data.data.district_id);
        win.setItem('districtname', response.data.data.district_name);
        win.setItem('stateid', response.data.data.state_id);
        win.setItem('statename', response.data.data.state_name);
        window.location.reload();
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callHomeAPI = async (subCategoryId) => {
    Axios.post(`${SERVICESINGLE_URL}/${subCategoryId}`, {locid:locid, districtid:districtid}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setCategory(response.data.category);
        setSubCategory(response.data.subcategory);
        setAds(response.data.ads);
        setReviews(response.data.reviews);
        setIsSet(true);
        setRatingCount(response.data.count);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callBookNowAPI = async (serviceDate) => {
    Axios.post(`${SERVICEBOOKING_URL}`, {locality_id:locid, service_subcat_id:subCategoryID, service_date:serviceDate, user_id:userId}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        ShowToast({ "message": 'Booking Confirmed. We will contact you soon.', "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleDateChange = event => {
    setServiceDate(event.target.value);
  };

  const checkBookNow = async (data) => {
    if (serviceDate.trim().length !== 0) {
      await callBookNowAPI(serviceDate);
    } else {
      ShowToast({ "message":'Please select Booking Date', "severity": "error" });
    }
  }

  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + servicesBack})`
  };

  return (
    <>
      { isSEOSet === true ? 
        <Helmet>
          <title>{seoData.meta_title.replace('#L#', locname).replace('#D#', districtname)}</title>
          <meta name="description" content={seoData.meta_desc.replace('#L#', locname).replace('#D#', districtname)} />
          <meta name="Keywords" content={seoData.meta_keywords.replace('#L#', locname).replace('#D#', districtname)} />
        </Helmet>
      :
        <></>
      }
      
      <Navbar />

        <div className='main services-back-img md-hide' style={category.image ? {backgroundImage: 'url(' + process.env.REACT_APP_AWS_S3_URL + '/' + category.image +')' }:myStyle}>
          <div className="container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} className="service-search">
                  <h3 className='heading-search md-hide'>Explore your needs!</h3>
                  <p className='service-single-heading-p'>{ subcategory.small_desc ? subcategory.small_desc : '' }</p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>

        <div className='main md-show' >
          <div className="container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} className="service-search">
                  <h3 className='heading-search md-hide'>Explore your needs!</h3>
                  <p className='service-single-heading-p'>{ subcategory.small_desc ? subcategory.small_desc : '' }</p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>

        <div className='main'  >
          <div className="container">
            <Box sx={{ flexGrow: 1 }} className='p-20'>
              <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                <Grid item md={12} lg={4}>
                  <div className='mtp-3'>
                    <br/>
                    <br/>
                      <h4 className='seclob-h1-sec'>Pick your date</h4>
                      <input 
                        type="date" 
                        placeholder='Select your date' 
                        id="serviceDate" 
                        className="form-input input-date block" 
                        onChange={handleDateChange}
                      />
                      <button type="button" onClick={checkBookNow} className='btn btn-primary btn-book-now btn-block'>Book Now</button>
                    <br/>
                    <br/>
                  </div>
                </Grid>
                <Grid item md={12} lg={8}>
                  <div className='p-20'>
                    <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                      <Grid item xs={6} className='p-20'>
                        {ads[0] && ads[0].image ? 
                          <img className='service-single-ads-img' src={process.env.REACT_APP_AWS_S3_URL + '/' + ads[0].image } />
                        :
                          <img className='service-single-ads-img' src={sad1} />
                        }
                      </Grid>
                      <Grid item xs={6} className='p-20'>
                        {ads[1] && ads[1].image ? 
                          <img className='service-single-ads-img' src={process.env.REACT_APP_AWS_S3_URL + '/' + ads[1].image } />
                        :
                          <img className='service-single-ads-img' src={sad2} />
                        }
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              
              <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                <Grid item md={12} lg={6}>
                  <h4 className='seclob-h1-sec'>Review & Ratings</h4>
                  <button className='rating-btn'>{ subcategory.rating ? subcategory.rating : '0' }</button> &nbsp;
                  <b>{ratingCount ? ratingCount : 0} rating</b>
                </Grid>
                <Grid item md={12} lg={6}>
                  <div style={{float:'Right'}}>
                    Start Your Review 
                    <br/>
                    <br/>
                    { subcategory.rating ? 
                      <>
                        {
                          subcategory.rating > 4.5 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                            </>
                          ) : subcategory.rating > 4 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarHalfIcon fontSize="large" className='rating-star-icon active' /> 
                            </>
                          ) : subcategory.rating > 3.5 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                            </>
                          ) : subcategory.rating > 3 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarHalfIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                            </>
                          ) : subcategory.rating > 2.5 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                            </>
                          ) : subcategory.rating > 2 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarHalfIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                            </>
                          ) : subcategory.rating > 1.5 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' />
                            </>
                          ) : subcategory.rating > 1 ? ( 
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarHalfIcon fontSize="large" className='rating-star-icon active' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                              <StarIcon fontSize="large" className='rating-star-icon' /> 
                            </>
                          ) : (
                            <>
                              <StarIcon fontSize="large" className='rating-star-icon ' />
                              <StarIcon fontSize="large" className='rating-star-icon ' />
                              <StarIcon fontSize="large" className='rating-star-icon ' />
                              <StarIcon fontSize="large" className='rating-star-icon ' />
                              <StarIcon fontSize="large" className='rating-star-icon ' />
                            </>
                          )
                        }
                      </>
                    : 
                      <>
                        <StarIcon fontSize="large" className='rating-star-icon ' />
                        <StarIcon fontSize="large" className='rating-star-icon ' />
                        <StarIcon fontSize="large" className='rating-star-icon ' />
                        <StarIcon fontSize="large" className='rating-star-icon ' />
                        <StarIcon fontSize="large" className='rating-star-icon ' />
                      </>
                    }
                    <br/>
                    <br/>
                    <button className='review-btn' onClick={handleOpen}><EditIcon /> Write a Review</button>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Accordion>
              <AccordionSummary
                className='rating-accordion'
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Reviews</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ flexGrow: 1 }} className='p-20'>
                  <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>


                  { isSet === true ? 
                    reviews.map( (resp, index) => {
                      return(
                        <>
                          <Grid item md={2} className='single-reviews-img-grid' >
                            <img src={userRating} className='single-reviews-img' />
                          </Grid>
                          <Grid item md={8} >
                            <b>{resp.name}</b>
                            <br/>
                            { resp.rating ? 
                              <>
                                {
                                  resp.rating > 4 ? ( 
                                    <>
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                    </>
                                  ) : resp.rating > 3 ? ( 
                                    <>
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon' /> 
                                    </>
                                  ) : resp.rating > 2 ? ( 
                                    <>
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon' /> 
                                    </>
                                  ) : resp.rating > 1 ? ( 
                                    <>
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon active' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon' /> 
                                      <StarIcon fontSize="large" className='rating-star-icon' /> 
                                    </>
                                  ) : (
                                    <>
                                      <StarIcon fontSize="large" className='rating-star-icon ' />
                                      <StarIcon fontSize="large" className='rating-star-icon ' />
                                      <StarIcon fontSize="large" className='rating-star-icon ' />
                                      <StarIcon fontSize="large" className='rating-star-icon ' />
                                      <StarIcon fontSize="large" className='rating-star-icon ' />
                                    </>
                                  )
                                }
                              </>
                            : 
                              <>
                                <StarIcon fontSize="large" className='rating-star-icon ' />
                                <StarIcon fontSize="large" className='rating-star-icon ' />
                                <StarIcon fontSize="large" className='rating-star-icon ' />
                                <StarIcon fontSize="large" className='rating-star-icon ' />
                                <StarIcon fontSize="large" className='rating-star-icon ' />
                              </>
                            }
                            <br/>
                            {resp.review} {index}
                          </Grid>
                          <Grid item md={2} >
                            <p>{ resp.created_at ? moment(resp.created_at).format("MMMM DD, YYYY  kk:mm a") : '' }</p>
                          </Grid>
                          {reviews.length -1 === index ? '' : <div className='line'></div> }
                        </>
                      )
                    }) 
                  :
                    <></>
                  }
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Terms & Conditions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: category.terms }} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Privacy Policy</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: category.privacy }} />
              </AccordionDetails>
            </Accordion>
            <div>
              <br />
              <h1>About {subcategory.name} Service</h1> 
              <br />
              <div dangerouslySetInnerHTML={{ __html: subcategory.about }} />
              

            </div>


            </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='review-model'>
            <img src={userRating} className='single-reviews-img' style={{maxWidth:30}} />
          </Box>
        </Modal>
      <Footer />
    </>
  )
}

export default ServicesSingle