import React from 'react'
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

import storeImg from '../../../images/eats.png';

function Eats() {
  return (
    <div className='main mtp-2'>
      <div className="container">
        <h2 className='seclob-h1'>Eats</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
            <Grid item xs={4} sm={3} md={3} lg={2} >
              <div className='store-grid-items'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='store-linear'>
                  <p className="p-h4">Briyani</p>
                  <p><span className='price-primary'>100/-</span><span className='price-cut'>150/-</span></p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2} >
              <div className='store-grid-items'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='store-linear'>
                  <p className="p-h4">Briyani</p>
                  <p><span className='price-primary'>100/-</span><span className='price-cut'>150/-</span></p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2} >
              <div className='store-grid-items'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='store-linear'>
                  <p className="p-h4">Briyani</p>
                  <p><span className='price-primary'>100/-</span><span className='price-cut'>150/-</span></p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2} className="sm-hide">
              <div className='store-grid-items'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='store-linear'>
                  <p className="p-h4">Briyani</p>
                  <p><span className='price-primary'>100/-</span><span className='price-cut'>150/-</span></p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2} className="lg-hide">
              <div className='store-grid-items'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='store-linear'>
                  <p className="p-h4">Briyani</p>
                  <p><span className='price-primary'>100/-</span><span className='price-cut'>150/-</span></p>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2} className="lg-hide">
              <div className='store-grid-items'>
                <img src={storeImg} alt="Maintance" />
                {/* <div className='store-linear'>
                  <p className="p-h4">Briyani</p>
                  <p><span className='price-primary'>100/-</span><span className='price-cut'>150/-</span></p>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default Eats