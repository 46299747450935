import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../../redux';
import { useDispatch } from 'react-redux';
import { Axios, SETTINGS_URL } from '../../../utils/apiUtils';

import searchImg from '../../../images/search.png';
import serviceImage from '../../../images/services/seclob_services.png';
import {AiOutlineClose} from "react-icons/ai";
import {GrFormClose} from "react-icons/gr";
import playStore from '../../../images/playstore.png';
import appStore from '../../../images/appstore.png';
import '../Home.css';
import downPhone from '../../../images/download_phone.png';
import HomeSearch from '../../../images/homesearch.png';
import { Box, Grid } from '@mui/material';



function Search() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);
  
  React.useEffect(() => {
    callListAPI();
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(SETTINGS_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.settings);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const [showSearchResultLen, setShowsearchResultLen] = useState(false);
  const showSearchResult = (event) => {
    if(event.target.value.length == 0){
      setShowsearchResultLen(false);
    } else {
      setShowsearchResultLen(true);
    }
  }
  const [openMobileSearchPage, setOpenMobileSearchPage] = useState(false);
  const [openDownloadApp, setOpenDownloadApp] = useState(true);

  return (
    
    <div>
      <div className='main home-search'>
        {openDownloadApp ?
          <div className="download-app" onClick={() => setOpenDownloadApp(false)} >
            <img src={downPhone} alt="download-seclob-app" className="download-phone" />
          </div>
          :
          <div className="download-app-highlight">
            <GrFormClose className="download-close" onClick={() => setOpenDownloadApp(true)} />
            <div className="download-phone-div">
              <img src={downPhone}  alt="download-seclob-app" className="download-phone-large" />
            </div>
            <p className='download-test-one'>Get a Better Experience on Mobile</p>
            <p className='download-test-two'>Download</p>
            <p className='download-test-two mb-10'>Seclob App Now!</p>
            { isSet === true ? 
              lists.map( (resp) => {
                return(
                <>
                  <a href={resp.play_link} target='_blank'><img className='download-store' src={playStore} alt="Seclob Play Store Link" /></a>
                  <a href={resp.app_link} target='_blank'><img className='download-store' src={appStore} alt="Seclob App Store Link" /></a>
                </>
                )
              })
            :
              <>
                <img className='download-store' src={playStore} alt="Seclob Play Store Link" />
                <img className='download-store' src={appStore} alt="Seclob App Store Link" />
              </>
            }
          </div> 
        }
        <div className="container-search-first">
          <div className="container">
            
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 0.2, sm:0.4, md: 0.8, lg: 1.6, xl:2.4 }}>
                <Grid item md={8} className="md-hide text-align-left">
                  <h1 className='text-primary inline-block search-heading font-60'>
                    What are you <br/>
                    <span className='font-40'>
                      <span className='text-secondary font-40'>looking</span>&nbsp;for...?
                    </span>
                  </h1>
                </Grid>
                
                <Grid item md={4} className="md-hide text-align-right">
                  <img className="homeSearchIcon" src={HomeSearch} alt="HomeSearch" />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        </div>
        
      <div className='main home-search'>
        <div className="container-search-second">
          <div className='search'>
            <img src={searchImg} alt="search-services" />
            <input type="text" id="webSearchInput" placeholder="Search anything..." onKeyUp={showSearchResult} />
            <button className="btn btn-search" type="button">Explore</button>
          </div>
          { showSearchResultLen != 0 &&  (
            <div className='search-result'>
              <ul>
                <li>Laptop Service</li>
                <li>Laptop Service</li>
                <li>Laptop Service</li>
                <li>Laptop Service</li>
              </ul>
              <div className='search-result-services'>
                <b>Our Services</b>
                <div className='search-result-services-grid'>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                  <div className='search-result-services-grid-items'>
                    <img src={serviceImage} alt="services" />&nbsp;
                    Services
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='main home-search-mobile'>
        <div className='container'>
          <div className='search-mobile'>
            <img src={searchImg} />
            <input type="text" placeholder="Search anything..." onClick={() => setOpenMobileSearchPage(!openMobileSearchPage)} />
          </div>
        </div>
      </div>
      {openMobileSearchPage == true && (
        <div className='search-mobile-page'>
          <div className='search-mobile-page-input'>
            <AiOutlineClose className='search-icon' onClick={() => setOpenMobileSearchPage(false)} />
            <div className='search-mobile'>
              <input type="text" placeholder="Search anything..." onClick="" />
              <img src={searchImg} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Search