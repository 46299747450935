import React from 'react'
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function CustomLink(props) {
  const resolvedPath = useResolvedPath(props.to);
  const isActivePath = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <Link to={props.to} className={ isActivePath ? `${props.className} active` : props.className }>{props.children}</Link>
  )
}

export default CustomLink