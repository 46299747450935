import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';
import { useDispatch } from 'react-redux';
import {encode as base64_encode} from 'base-64';

import Footer from './Footer'
import Navbar from './Navbar'
import './Services.css'
import './Common.css'
import Store from './Home/Store';
import Eats from './Home/Eats';
import Jobs from './Home/Jobs';

import {MdOutlineKeyboardBackspace} from "react-icons/md";
import vechicleBack  from '../../images/vechicle/vechicle-back.png';
import vechicleAds from '../../images/vechicle/vechicle-ads.png';
import vechicleAds1 from '../../images/vechicle/add1.png';
import vechicleAds2 from '../../images/vechicle/add2.png';

import vecCarWash from '../../images/vechicle/car_wash.png';
import vecCarAcc from '../../images/vechicle/car_accessories.png';
import vecJeep from '../../images/vechicle/jeep.png';
import vecBullet from '../../images/vechicle/bullet.png';
import vecBike from '../../images/vechicle/bike.png';
import { Axios, HOMEVECHICLESERVICESLIST_URL, VECHICLESERVICEHOME_URL } from '../../utils/apiUtils';

function Vechicle() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const win = window.localStorage;

  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [home, setHome] = useState([]);
  const [isSetHome, setIsSetHome] = useState(false);
  const [locid, setLocId] = useState(0);
  const [locname, setLocName] = useState('');
  const [districtname, setDistrictName] = useState('');
  const [backImage, setBackImage] = useState('');

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const { ShowLocDropdown } = bindActionCreators(
		actions.locDropdown,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();
    callHomeAPI();

    if(win.getItem('locid')) setLocId(win.getItem('locid'));
    if(win.getItem('locname')) setLocName(win.getItem('locname'));
    if(win.getItem('districtname')) setDistrictName(win.getItem('districtname'));
  }, []);

  const callHomeAPI = async (show_page) => {
    Axios.post(VECHICLESERVICEHOME_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setHome(response.data);
        setIsSetHome(true);
        setBackImage(response.data.category.image);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callListAPI = async (show_page) => {
    Axios.post(HOMEVECHICLESERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + vechicleBack})`
  };
  const [showSearchResultLen, setShowsearchResultLen] = useState(false);
  const showSearchResult = (event) => {
    if(event.target.value.length === 0){
      setShowsearchResultLen(false);
    } else {
      setShowsearchResultLen(true);
    }
  }

  const goToURL = (url) => {
    if(locid) {
      navigate(url);
    } else {
      ShowLocDropdown();
    }
  }

  return (
    <>
      <Navbar />

      <div className='main services-back-img'  style={backImage ? {backgroundImage: 'url(' + process.env.REACT_APP_AWS_S3_URL + '/' + backImage +')' } : myStyle}>
        <div className="container">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
              <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} className="service-search">
                <h3 className='heading-search md-hide mbi-5 mti-4'>Vechicle Services</h3>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <div className='main home-vechicle'>
        <div className="container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>

            { isSet === true ? 
              lists.map( (resp) => {
                return(
                  <Grid item xs={3} sm={3} md={2} lg={2}  key={resp.id}>
                    <div className='vechicle-grid'>
                      <button className='btn btn-vechicle' onClick={() => {goToURL( locid ? '/' + districtname + '/' + resp.slug_name + '-in-' + locname + '/' + 'd-' + base64_encode(resp.id) + '-' + base64_encode(locid) : resp.slug_name + '/' + base64_encode(resp.id) )}}>
                        <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} />
                        <p>{resp.name}</p>
                      </button>
                    </div>
                  </Grid>
                )
              }) 
            :
              <>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
          
              </>
            }
            
          </Grid>
        </Box>

        { isSetHome ?
          <>
          <h3 className='seclob-h1 mtp-3 vechicle-h1'>Why Choose Us</h3>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
              <Grid item xs={12} sm={12} md={4} lg={4} >
                <div className='vechicle-ads-one-sec'>
                  <img src={vechicleAds} alt="Vechicle Services" />
                  <h4>{home.cms.heading1}</h4>
                  <p>{home.cms.content1}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} >
                <div className='vechicle-ads-one-main'>
                  <img src={vechicleAds} alt="Vechicle Services" />
                  <h4>{home.cms.heading2}</h4>
                  <p>{home.cms.content2}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} >
                <div className='vechicle-ads-one-sec'>
                  <img src={vechicleAds} alt="Vechicle Services" />
                  <h4>{home.cms.heading3}</h4>
                  <p>{home.cms.content3}</p>
                </div>
              </Grid>
            </Grid>
          </Box>
          </>
        :
          <></>
        }

        </div>
      </div>
      
      { isSetHome ?
        <div className='main home-vechicle'>
          <div className="container">
          <Box sx={{ flexGrow: 1 }} className='mtp-3'>
              <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <img src={process.env.REACT_APP_AWS_S3_URL + '/' + home.cms.webbanner1} alt="add-one" className='vechicle-ads-back' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <img src={process.env.REACT_APP_AWS_S3_URL + '/' + home.cms.webbanner2} alt="add-two" className='vechicle-ads-back' />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      :
        <></>
      }
    
      <Footer />
    </>
  )
}

export default Vechicle