import React from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";

const FormikTextField = props => {
	const {
		variant,
		name,
		label,
		placeholder,
		defaultValue,
		className,
		InputProps,
		inputProps,
		type,
		onChange,
		value,
		disabled,
		size
	} = props;

	const Content = ({ form, meta }) => {
		return (
			<React.Fragment>
				<TextField
					type={type || "text"}
					fullWidth
					name={name}
					id={name}
					value={value}
					placeholder={placeholder}
					label={label}
					variant={variant || "outlined"}
					defaultValue={meta.initialValue || defaultValue}
					error={(meta.touched || form.submitCount > 0) && Boolean(meta.error)}
					helperText={(meta.touched || form.submitCount > 0) && meta.error}
					className={className || ""}
					InputProps={InputProps}
					inputProps={inputProps}
					onChange={onChange}
					disabled={disabled}
					size={size}
				/>
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default FormikTextField;
