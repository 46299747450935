import React, {useState} from 'react';
import { Link } from "react-router-dom";
import CustomLink from './CustomLink';

import logo1 from '../../images/full_logo_web.png';
import logo2 from '../../images/full_logo_mobile.png';
import './Navbar.css';


import { IoMdClose, IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { RiMenu3Fill } from "react-icons/ri";
import {AiOutlineCloseCircle, AiOutlineFileText, AiOutlineClose} from "react-icons/ai";
import {BsTelephone, BsExclamationCircle } from "react-icons/bs";
import {FiLock} from "react-icons/fi";
import {GrLocation} from "react-icons/gr";
import { Axios, LOCATIONFETCH_URL } from '../../utils/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';


import userImg  from '../../images/user.png';

function Navbar() {
  const dispatch = useDispatch();
  const locDropdownState = useSelector(state => state.locDropdown.open);

  const win = window.localStorage;

  const [locData, setLocData] = useState([]);
  const [isLocSet, setIsLocData] = useState(false);
	const [click, setClick] = useState(false);

  const [search, setSearch] = useState('');
  const [locid, setLocId] = useState(0);
  const [locname, setLocName] = useState('');
  const [districtid, setDistrictId] = useState(0);
  const [districtname, setDistrictName] = useState('');
  const [stateid, setStateId] = useState(0);
  const [statename, setStateName] = useState('');
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [userKey, setUserKey] = useState('');

  
  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const { ShowLocDropdown, HideLocDropdown } = bindActionCreators(
		actions.locDropdown,
		dispatch
	);

  let logo = logo1;
  if(window.innerWidth <= 900) {
		logo = logo2;
	}

  React.useEffect(() => {
    if(win.getItem('locid')) setLocId(win.getItem('locid'));
    if(win.getItem('locname')) setLocName(win.getItem('locname'));
    if(win.getItem('districtid')) setDistrictId(win.getItem('districtid'));
    if(win.getItem('districtname')) setDistrictName(win.getItem('districtname'));
    if(win.getItem('stateid')) setStateId(win.getItem('stateid'));
    if(win.getItem('statename')) setStateName(win.getItem('statename'));
    if(win.getItem('userId')) setUserId(win.getItem('userId'));
    if(win.getItem('userName')) setUserName(win.getItem('userName'));
    if(win.getItem('userKey')) setUserKey(win.getItem('userKey'));
  }, []);

  const callLocationFetchAPI = async (value) => {
    Axios.post(LOCATIONFETCH_URL, {search: value}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLocData(response.data.data);
        setIsLocData(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  if((locDropdownState === true) && (isLocSet === false)) {
    callLocationFetchAPI();
  }

  const setLocationData = (locid, locname, districtid, districtname, stateid, statename) => {
    HideLocDropdown();
    win.setItem('locid', locid);
    win.setItem('locname', locname);
    win.setItem('districtid', districtid);
    win.setItem('districtname', districtname);
    win.setItem('stateid', stateid);
    win.setItem('statename', statename);
    window.location.reload();
  }

  const setSearchKey = (value) => {
    setSearch(value);
    callLocationFetchAPI(value);
  }

  return (
    <nav className='navbar main'>
      <div className="container">
        <div className="navbar-main">
          <div className="navbar-logo">
            <Link to="/">
              <img src={logo} alt="seclob-logo" />
            </Link>
          </div>
          <div className="navbar-links">
            <ul>
              <li>
                <CustomLink to="/" className='navbar-link' >Home</CustomLink>
              </li>
              <li>
                <CustomLink to="/offer" className='navbar-link' >Offer</CustomLink>
              </li>
            </ul>
          </div>
          <div className="navbar-buttons">
            <CustomLink to="/partner/register" className='btn btn-primary' >Register as Partner</CustomLink>
            <button type="button" className='btn btn-primary' onClick={() => {locDropdownState ? HideLocDropdown() : ShowLocDropdown()}}>
              { locid ?
                locname ? locname + ", " + districtname + ", " + statename : ''
              : 
                'Select your Location'
              }
              { locDropdownState ? <IoMdArrowDropdown /> : <IoMdArrowDropright /> }
            </button>
            {
              userId ? 
                <CustomLink to="/" className='btn btn-secondary display-content' >
                  <div className='nav-profile-div'>
                    <img src={userImg} className='user-nav-img' />
                  </div>
                  <div className='nav-profile-p'>
                    <p>{userName}</p>
                  </div>
                </CustomLink>
              :
                <CustomLink to="/login" className='btn btn-secondary' >Login</CustomLink>
            }
          </div>
          <div className="navbar-buttons-mobile">
            <button type="button" className='btn btn-primary loc-btn' onClick={() => {locDropdownState ? HideLocDropdown() : ShowLocDropdown()}}>
              <GrLocation /> 
              { locid ?
                locname ? locname + ", " + districtname + ", " + statename : ''
              : 
                'Select your Location'
              }
              { locDropdownState ? <IoMdArrowDropdown /> : <IoMdArrowDropright /> }
            </button>
          </div>
          <div className="navbar-hamburger" onClick={() => setClick(!click)}>
            { click ? <IoMdClose /> : <RiMenu3Fill /> }
          </div>
          <div className={ locDropdownState ? 'navbar-location show' : 'navbar-location none' }>
            <div className='navbar-location-your-loc'>
              <p>Your Location</p>
              <span><AiOutlineClose className='icon' onClick={() => HideLocDropdown()} /></span>
            </div>
            <input 
              type="search" 
              className='navbar-location-search' 
              id="searchLocation" 
              placeholder='Search Your location here...' 
              onChange={ (e) => {setSearchKey(e.target.value)} } 
            />
            <ul>
              { isLocSet === true ? 
                locData.map( (resp) => {
                  return( <li key={resp.id}><a href="#" onClick={() => setLocationData(resp.id, resp.name, resp.district_id, resp.district_name, resp.state_id, resp.state_name)} >{resp.name}, {resp.district_name}, {resp.state_name}</a></li> )
                }) 
              :
                <li><a href="#" >No results Found!</a></li> 
              }
            </ul>
          </div>
          <div className={ click ? 'navbar-hamburger-sidebar show' : 'navbar-hamburger-sidebar none' }>
            <div className='navbar-hamburger-close' onClick={() => setClick(false)}>
              <AiOutlineCloseCircle className='icon' />
            </div>
            <ul>
                <li>
                  <CustomLink to="/login" className='btn btn-primary btn-block' >Sign In / Sign Up</CustomLink>
                </li>
                <li>
                  <CustomLink to="/partner/register" className='btn btn-primary btn-block' >Register as Partner</CustomLink>
                </li>
                <li>
                  <CustomLink to="/contact-us" className='btn btn-grey btn-block btn-left' ><BsTelephone /> &emsp; Contact Us</CustomLink>
                </li>
                <li>
                  <CustomLink to="/about-us" className='btn btn-grey btn-block btn-left' ><BsExclamationCircle /> &emsp; About Us</CustomLink>
                </li>
                <li>
                  <CustomLink to="/loterms-conditionsgin" className='btn btn-grey btn-block btn-left' ><AiOutlineFileText /> &emsp; Terms & Conditions</CustomLink>
                </li>
                <li>
                  <CustomLink to="/privacy-policy" className='btn btn-grey btn-block btn-left' ><FiLock /> &emsp; Privacy Policy</CustomLink>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar