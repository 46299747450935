import React, { useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import FormikTextField from '../FormikTextField/FormikTextField';
import { Regx } from '../../utils/Regx';
import FormikForm from '../../components/FormikForm/FormikForm';

import jwt_decode from "jwt-decode";


import logo from '../../images/full_logo_web.png';
import logoBack from '../../images/seclob_back2.png';
import './Login.css';
import CustomLink from './CustomLink';
import { useDispatch } from 'react-redux';
import { Axios, CUSTOMERREGISTER } from '../../utils/apiUtils';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';

function GoogleRegister() {
  const win = window.localStorage;
  const { cred } = useParams();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [userKey, setUserKey] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [value, setValue] = React.useState('');

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    let credData = jwt_decode(cred);
    setName(credData.name);
    setEmail(credData.email);
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  
  const Validator = Yup.object().shape({
    name: Yup.string()
      .required("Name Required.")
      .matches(Regx.plainText, "Invalid Name."),
    phone: Yup.string()
      .required("Phone Number Required.")
      .matches(Regx.mobile, "Invalid Phone Number."),
    email: Yup.string()
      .required("Email Address Required.")
      .matches(Regx.email, "Invalid Email Address."),
    password: Yup.string()
      .required("Password Required.")
      .min(6, "Password too Short.")
  });

  const handleSubmit = async (data) => {
    await callRegisterAPI(data);
  }

  const callRegisterAPI = async (inputPayload) => {
    Axios.post(CUSTOMERREGISTER, inputPayload).then((response) => {
      if (response.data.sts === "01") {
        let user = response.data.user;
        win.setItem('userId', user.id);
        win.setItem('userName', user.name);
        win.setItem('userKey', user.user_key);

        setUserId(user.id);
        setUserName(user.name);
        setUserKey(user.user_key);

        navigate('/');
      }
    }).catch( err=> {
      if (err.response.status === 400) {
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      } else if (err.response && err.response.data && err.response.data.msg )
        console.log(err.response);
      else
        console.log(err.message);
    });
  }


  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + logoBack})`
  };

  return (
    <div className='main login-image'  style={myStyle}>
      <div className="container-sec">
        <div className="login">
          <Link to="/">
            <img src={logo} className="" alt="seclob-logo" />
          </Link>
          <div className="card-main">
            <div className="card">
              <div className="card-header">
                <h3>Create Account</h3>
              </div>
              <div className='card-body' >
                <FormikForm validator={Validator}  onSubmit={handleSubmit} 
                  initialValues={{'name':name, 'email':email}} >
                  <FormikTextField 
                    name="name"
                    className="form-input block input-login mt-2"
                    label="Enter your Name"
                    size="medium"
                    value={name ? name : ''}
                    setValue={setValue}
                    onChange={handleChange}
                  />
                  <FormikTextField 
                    name="phone"
                    className="form-input block input-login mt-2"
                    label="Enter your Phone Number"
                    size="medium"
                  />
                  <FormikTextField 
                    name="email"
                    className="form-input block input-login mt-2"
                    label="Enter your Email Address"
                    size="medium"
                    value={email ? email : ''}
                    setValue={setValue}
                    onChange={handleChange}
                  />
                  <FormikTextField 
                    name="password"
                    className="form-input block input-login mt-2"
                    label="Enter your Password"
                    size="medium"
                  />
                  <br/>
                  <br/>
                  <button className='btn btn-login btn-block mlr-0 mt-3' id="loginMobileBtn">Register</button>
                  <br/>
                  <br/>
                </FormikForm>
              </div>
            </div>
          </div>

        </div>
        <div className="login-footer" >
          <p className='login-footer-p'>Copyright 2022 Seclob Technologies Pvt.Ltd. All Rights Reserved | Designed & Developed By Seclob Technologies</p>
        </div>
      </div>
    </div>
  )
}


export default GoogleRegister