import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import { Axios, HOMESEO_URL } from '../../utils/apiUtils';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';


import Footer from './Footer';
import Ads from './Home/Ads';
import Eats from './Home/Eats';
import Jobs from './Home/Jobs';
import Maintance from './Home/Maintance';
import Mostused from './Home/Mostused';
import Search from './Home/Search';
import Services from './Home/Services';
import Store from './Home/Store';
import HomeVechicle from './Home/HomeVechicle';
import Navbar from './Navbar';


function Home() {
  const dispatch = useDispatch();

  const [seoData, setSEOData] = useState([]);
  const [isSEOSet, setIsSEOSet] = useState(false);

  
  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callSEOAPI();
  }, []);
  
  const callSEOAPI = async () => {
    Axios.post(HOMESEO_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setSEOData(response.data.data[0]);
        setIsSEOSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }


  return (
    <>
      { isSEOSet === true ? 
        <Helmet>
          <title>{seoData.home_meta_title}</title>
          <meta 
            name="description" 
            content={seoData.home_meta_desc}
          />
          <meta 
            name="Keywords" 
            content={seoData.home_meta_keywords}
          />
        </Helmet>
      :
        <></>
      }

      <Navbar />
      <Search />
      <Services />
      <Ads />
      <HomeVechicle />
      <Maintance />
      <Mostused />
      <Store />
      <Eats />
      <Jobs />
      
      <Footer />
    </>
  )
}

export default Home