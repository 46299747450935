import React from 'react'
import { Link } from "react-router-dom";

import logo from '../../images/full_logo_web.png';
import logoBack from '../../images/seclob_back2.png';
import './Login.css';
import CustomLink from './CustomLink';


function Register() {

  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + logoBack})`
  };

  return (
    <div className='main login-image'  style={myStyle}>
      <div className="container-sec">
        <div className="login">
          <Link to="/">
            <img src={logo} className="" alt="seclob-logo" />
          </Link>
          <div className="card-main">
            <div className="card">
              <div className="card-header">
                <h3>Create Account</h3>
              </div>
              <div className='card-body' >
                <input type="text" placeholder='Enter your Name' id="number" className="form-input block input-login" />
                <input type="text" placeholder='Enter your phone Number' id="number" className="form-input block input-login" />
                <input type="text" placeholder='Enter your email Address' id="number" className="form-input block input-login" />
                <input type="text" placeholder='Enter your Password' id="number" className="form-input block input-login" />
                <input type="text" placeholder='Confirm Password' id="number" className="form-input block input-login" />
                <button className='btn btn-login btn-block' id="loginMobileBtn">Register</button>
                {/* <a className='login-a right'>Forget Password?</a> */}
                <br/>
                <br/>
                {/* <span className='login-span'>
                  <p className='inline-block login-p'>Don’t have an account?<CustomLink to="/register" className='login-a'>Create one</CustomLink></p>
                </span> */}
              </div>
            </div>
          </div>

        </div>
        <div className="login-footer" >
          <p className='login-footer-p'>Copyright 2022 Seclob Technologies Pvt.Ltd. All Rights Reserved | Designed & Developed By Seclob Technologies</p>
        </div>
      </div>
    </div>
  )
}

export default Register