export const AUTH_CONTEXT = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
}

export const TOAST_CONTEXT = {
  TOAST_SHOW: 'TOAST_SHOW',
  TOAST_HIDE: 'TOAST_HIDE'
}

export const LOC_DROPDOWN_CONTEXT = {
  LOC_DROPDOWN_SHOW: 'LOC_DROPDOWN_SHOW',
  LOC_DROPDOWN_HIDE: 'LOC_DROPDOWN_HIDE'
}