/**
 *
 * This is Toast component which is used in app.js file to show various toasts
 * and this component will manage by ToastReducer,ToastAction.
 *
 */

 import React from 'react';
 import Snackbar from '@mui/material/Snackbar';
 import MuiAlert from '@mui/material/Alert';
 import { useDispatch } from 'react-redux';
 import { actions } from '../../redux';
 import { bindActionCreators } from 'redux';
 
 const Alert = React.forwardRef(function Alert(
    props,
    ref
 ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });
 
 const Toast = (props) => {
    const dispatch = useDispatch();
    const { HideToast } = bindActionCreators(
      actions.toast,
      dispatch
    );

    const { open, severity, message } = props;
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      HideToast();
    };
 
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} className="aj-alert">
          {message}
        </Alert>
      </Snackbar>
    );
 };
 
 export default Toast;
 