import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';
import { useDispatch } from 'react-redux';
import { Axios, SERVICESEO_URL, SERVICES_URL } from '../../utils/apiUtils';
import {encode as base64_encode} from 'base-64';

import Footer from './Footer'
import Navbar from './Navbar'
import './Services.css'

 
import searchImg from '../../images/search.png';
import servicesBack  from '../../images/service/services-back.png';
import { Helmet } from 'react-helmet';


function Services() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const win = window.localStorage;

  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [search, setSearch] = useState('');
  const [locid, setLocId] = useState(0);
  const [locname, setLocName] = useState('');
  const [districtname, setDistrictName] = useState('');
  const [backImage, setBackImage] = useState('');

  const [seoData, setSEOData] = useState([]);
  const [isSEOSet, setIsSEOSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callSEOAPI();
    callListAPI();

    if(win.getItem('locid')) setLocId(win.getItem('locid'));
    if(win.getItem('locname')) setLocName(win.getItem('locname'));
    if(win.getItem('districtname')) setDistrictName(win.getItem('districtname'));
  }, []);

  const callSEOAPI = async () => {
    Axios.post(SERVICESEO_URL+'/1', {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setSEOData(response.data.data[0]);
        setIsSEOSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callListAPI = async (show_page, search) => {
    Axios.post(SERVICES_URL, {search:search}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setIsSet(true);
        setBackImage(response.data.backImage.image3);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const setSearchData = async (event) => {
    callListAPI(1, event);
  }

  const myStyle={
    backgroundImage: `url(${process.env.PUBLIC_URL + servicesBack})`
  };


  return (
    <>

      { isSEOSet === true ? 
        <Helmet>
          <title>{seoData.meta_title}</title>
          <meta 
            name="description" 
            content={seoData.meta_desc}
          />
          <meta 
            name="Keywords" 
            content={seoData.meta_keywords}
          />
        </Helmet>
      :
        <></>
      }

      <Navbar />


        <div className='main services-back-img e ' style={backImage?{backgroundImage: 'url(' + process.env.REACT_APP_AWS_S3_URL + '/' + backImage +')' }:myStyle}>
          <div className="container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} className="service-search">
                  <h3 className='heading-search md-hide'>Explore your needs!</h3>
                  <div className='search search-fill services-search'>
                  <img src={searchImg} />
                    <input type="text" id="webSearchInput" placeholder="Search anything..." onChange={ (e) => {setSearchData(e.target.value); } } />
                    <button className="btn btn-search services-search-btn" type="button">Explore</button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>

      

      <div className='main'  >
        <div className="container">
          { isSet === true ? 
            Object.entries(lists).map( ([key, object], index) => {
              return(
                <div key={key}>
                  <h3 className='heading-two'>{key}</h3>
                  <Box sx={{ flexGrow: 1 }} >
                    <Grid container spacing={{ xs: 0, sm:0, md: 0, lg: 0, xl:0 }}>
                      { 
                        object.map( (resp) => {
                          return (
                            <Grid item xs={3} sm={2} md={1.5} lg={1.2} key={resp.id}>
                              <div className='services-items'>
                                <Link to={locid ? '/' + districtname + '/' + resp.slug_name + '-in-' + locname + '/' + 'd-' + base64_encode(resp.id) + '-' + base64_encode(locid) : resp.slug_name + '/' + base64_encode(resp.id) }>
                                  <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} />
                                  <p style={{minHeight:40}}>{resp.name}</p>
                                </Link>
                              </div>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Box> 
                </div>
              )
            }) 
            :
            <></>
          }
        </div>
      </div>

      
      <Footer />
    </>
  )
}

export default Services