import React, { useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../../redux';
import { useDispatch } from 'react-redux';
import { Axios, MOSTUSEDADS_URL } from '../../../utils/apiUtils';


function Mostused() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lists, setLists] = useState([]);
  const [lists2, setLists2] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(MOSTUSEDADS_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.mostUsed);
        setLists2(response.data.ads3);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  var settings = {
    className: "center",
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  let locListz = ['Alappuzha', 'Ernakulam', 'Idukki', 'Kannur', 'Kasaragod', 'Kollam', 'Kottayam', 'Kozhikode', 'Malappuram', 'Palakkad', 'Pathanamthitta', 'Thiruvananthapuram', 'Thrissur', 'Wayanad'];

  return (
    <div className='main mtp-2'>
      <div className="container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>
            <Grid item xs={12} sm={12} md={6}  >
              <div className="most-grid-one" >
                <h2 className='seclob-h1-sec'>Most Used Services</h2>
                <Slider {...settings}>
                  { isSet === true ? 
                    lists.map( (resp) => {
                      return(
                        <div className='most-one-items'  key={resp.name}>
                          <div className="most-one-container">
                            <br />
                            <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} />
                            <p className="p-h4">{resp.name}</p>
                            <p>Seclob Service at { locListz[Math.floor(Math.random() * locListz.length)]}</p>
                            <div className='most-items-block'>
                              <p>{Math.floor(Math.random() * 100)} minutes ago..</p>
                            </div>
                          </div>
                        </div>
                      )
                    }) 
                  :
                    <></>
                  }

                </Slider>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
            { isSet === true ? 
                lists2.map( (resp) => {
                  return(
                    <img  src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} className='most-img-one'  key={resp.name} />
                  )
                }) 
              :
                <>
                <Skeleton variant="rounded" width="100%" height={300} style={{backgroundColor:'#EBFCFA', borderRadius:30}} />
                </>
              }
              
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default Mostused