import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { AuthReducers } from './authReducers';
import { ToastReducer } from "./toastReducers";
import { LocDropdownReducer } from "./locToggleReducers";

const persistConfig = {
    key: 'counter',
    storage,
    blacklist: ['toast']
};

const reducers = combineReducers({
    auth: AuthReducers,
    toast: ToastReducer,
    locDropdown: LocDropdownReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default persistedReducer;