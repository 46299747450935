import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';



export const HOMESERVICESLIST_URL = '/customer/home/services';
export const HOMEVECHICLESERVICESLIST_URL = '/customer/home/services/vechicle';
export const HOMESERVICESSUBCATLIST_URL = '/customer/home/services/subcategory';



export const VECHICLESERVICEHOME_URL = '/customer/vechicle/home';


export const FOOTERLIST_URL = '/customer/footer';
export const ADVERTISMENTS_URL = '/customer/home/advertiments';
export const MOSTUSEDADS_URL = '/customer/home/mostusedads';
export const SETTINGS_URL = '/customer/settings';
export const SERVICES_URL = '/customer/services';


export const LOCATIONFETCH_URL = '/customer/location';


export const HOMESEO_URL = '/customer/seo/home';
export const SERVICESEO_URL = '/customer/seo/service';
export const SERVICESINGLESEO_URL = '/customer/seo/service/subcat';
export const SERVICESINGLE_URL = '/customer/service/single';
export const SERVICEBOOKING_URL = '/customer/service/booking';


export const CUSTOMERGOOGLEVERTFY = '/customer/google/verify';
export const CUSTOMERLOGIN = '/customer/login';
export const CUSTOMERREGISTER = '/customer/register';


export const Axios = axios;
