import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../../redux';
import { useDispatch } from 'react-redux';


import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Axios, HOMESERVICESLIST_URL } from '../../../utils/apiUtils';


import Service from '../../../images/serviceicons/Services.png';
import ExploreX from '../../../images/serviceicons/Explorex.png';
import SecDeals from '../../../images/serviceicons/secdeals.png';
import JobSpark from '../../../images/serviceicons/jobspark.png';
import VehiFix from '../../../images/serviceicons/Vehifix.png';
import ECard from '../../../images/serviceicons/Ecard.png';
import SecMart from '../../../images/serviceicons/SecMart.png';
import Eatzy from '../../../images/serviceicons/Eatzy.png';
import Mediconnect from '../../../images/serviceicons/Mediconnect.png';
import Lawlink from '../../../images/serviceicons/lawlink.png';
import RentAll from '../../../images/serviceicons/rentall.png';
import GoCab from '../../../images/serviceicons/Gocab.png';
import Rooms from '../../../images/serviceicons/Room.png';
import TopUp from '../../../images/serviceicons/Topup.png';
import Quickhands from '../../../images/serviceicons/Quick hands.png';
import BuySell from '../../../images/serviceicons/buyNsell.png';
import Soulmatez from '../../../images/serviceicons/Soulmatez.png';
import LearnZone from '../../../images/serviceicons/Education.png';
import Blog from '../../../images/serviceicons/BLOG.png';
import News from '../../../images/serviceicons/News.png';


function Services() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const win = window.localStorage;

  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [locid, setLocId] = useState(0);
  const [locname, setLocName] = useState('');
  const [districtname, setDistrictName] = useState('');
  const [locButtonClick, setLocButtonClick] = useState(false);

  const { ShowLocDropdown } = bindActionCreators(
		actions.locDropdown,
		dispatch
	);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();

    if(win.getItem('locid')) setLocId(win.getItem('locid'));
    if(win.getItem('locname')) setLocName(win.getItem('locname'));
    if(win.getItem('districtname')) setDistrictName(win.getItem('districtname'));
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(HOMESERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const goToURL = (url) => {
    if(locid) {
      if(url.startsWith('/')) {
        navigate( districtname + '/' + locname + url);
      } else {
        window.location.href = url
      }
    } else {
      ShowLocDropdown();
    }
  }

  return (
    <div className='main'>
      <div className="container">
        <div className="home-services">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 0.5, sm:0.8, md: 1, lg: 1.4, xl:2 }}>
              
              { isSet === true ? 
                lists.map( (resp) => {
                  return(
                    <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} key={resp.name}>
                      <div className='service-items'>
                        <button className='btn' onClick={() => {goToURL(resp.url)}}>
                          <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} />
                          <p>{resp.name}</p>
                        </button>
                      </div>
                    </Grid>
                  )
                }) 
                :
                <>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Service} alt="Service"/> 
                        <p>Service</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={ExploreX} alt="ExploreX"/> 
                        <p>ExploreX</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={SecDeals} alt="SecDeals"/> 
                        <p>SecDeals</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={JobSpark} alt="JobSpark"/> 
                        <p>JobSpark</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={VehiFix} alt="VehiFix"/> 
                        <p>VehiFix</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={ECard} alt="ECard"/> 
                        <p>E-Card</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={SecMart} alt="SecMart"/> 
                        <p>SecMart</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Eatzy} alt="Eatzy"/> 
                        <p>Eatzy</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Mediconnect} alt="Mediconnect"/> 
                        <p>Mediconnect</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Lawlink} alt="Lawlink"/> 
                        <p>Lawlink</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={RentAll} alt="RentAll"/> 
                        <p>RentAll</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={GoCab} alt="GoCab"/> 
                        <p>GoCab</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Rooms} alt="Rooms"/> 
                        <p>Rooms</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={TopUp} alt="TopUp"/> 
                        <p>TopUp</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Quickhands} alt="Quickhands"/> 
                        <p>Quickhands</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={BuySell} alt="BuySell"/> 
                        <p>Buy & Sell</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Soulmatez} alt="Soulmatez"/> 
                        <p>Soulmatez</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={LearnZone} alt="LearnZone"/> 
                        <p>LearnZone</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={Blog} alt="Blog"/> 
                        <p>Blog</p>
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={2.4} sm={2.4} md={2.4} lg={1.2} className="md-hide">
                    <div className='service-items'>
                      <button className='btn' onClick={() => {goToURL('/services')}} style={{minHeight:100, backgroundColor:'#EBFCFA'}}>
                        <img src={News} alt="News"/> 
                        <p>News</p>
                      </button>
                    </div>
                  </Grid>
                </>
              }
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  )
}

export default Services