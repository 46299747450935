import React, { useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../../redux';
import { useDispatch } from 'react-redux';
import { Axios, ADVERTISMENTS_URL } from '../../../utils/apiUtils';


function Ads() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lists, setLists] = useState([]);
  const [lists2, setLists2] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callListAPI();
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(ADVERTISMENTS_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.ads1);
        setLists2(response.data.ads2);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  var settings = {
    className: "center",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear"
  };

  return (
    <div className='main mtp-2'>
      <div className="container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 1, sm:2, md: 2, lg: 3, xl:4 }}>
            <Grid item xs={12} sm={12} md={6.9}>
            <Slider {...settings}>
              { isSet === true ? 
                lists.map( (resp) => {
                  return(
                    <div className="add-one-img"  key={resp.name}>
                      <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} />
                    </div>
                  )
                }) 
              :
                <>
                  <Skeleton variant="rounded" width="100%" height={300} style={{backgroundColor:'#EBFCFA', borderRadius:30}} />
                </>
              }
            </Slider>
            </Grid>
            <Grid item xs={12} sm={12} md={5.1} className="add-one-grid-one">
            { isSet === true ? 
                lists2.map( (resp) => {
                  return(
                    <img className='add-two-one' src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name}  key={resp.name} />
                  )
                }) 
              :
                <>
                  <Skeleton variant="rounded" width="100%" height={140} style={{backgroundColor:'#EBFCFA', borderRadius:30}} />
                  <Skeleton variant="rounded" width="100%" height={140} style={{marginTop:20, backgroundColor:'#EBFCFA', borderRadius:30}} />
                  </>
              }
              
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default Ads