import { LOC_DROPDOWN_CONTEXT } from '../context';

export const ShowLocDropdown = () => {
  return (dispatch) => {
    dispatch({
      type: LOC_DROPDOWN_CONTEXT.LOC_DROPDOWN_SHOW
    })
  }
}

export const HideLocDropdown = () => {
  return (dispatch) => {
    dispatch({
      type: LOC_DROPDOWN_CONTEXT.LOC_DROPDOWN_HIDE
    })
  }
}