import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../../redux';
import { useDispatch } from 'react-redux';
import {encode as base64_encode} from 'base-64';

import { Axios, HOMEVECHICLESERVICESLIST_URL } from '../../../utils/apiUtils';

function HomeVechicle() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const win = window.localStorage;

  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [locid, setLocId] = useState(0);
  const [locname, setLocName] = useState('');
  const [districtname, setDistrictName] = useState('');

  const { ShowLocDropdown } = bindActionCreators(
		actions.locDropdown,
		dispatch
	);

  
  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  
  React.useEffect(() => {
    callListAPI();

    if(win.getItem('locid')) setLocId(win.getItem('locid'));
    if(win.getItem('locname')) setLocName(win.getItem('locname'));
    if(win.getItem('districtname')) setDistrictName(win.getItem('districtname'));
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(HOMEVECHICLESERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const goToURL = (url) => {
    if(locid) {
      navigate(url);
    } else {
      ShowLocDropdown();
    }
  }

  return (
    <div className='main mtp-1 home-vechicle'>
      <div className="container">
        <h2 className='seclob-h1'>Vehicle Services</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:1, md: 2, lg: 3, xl:4 }}>

            { isSet === true ? 
              lists.map( (resp) => {
                return(
                  <Grid item xs={3} sm={3} md={2} lg={2}  key={resp.id}>
                    <div className='vechicle-grid'>
                      <button className='btn btn-vechicle' onClick={() => {goToURL( locid ? '/' + districtname + '/' + resp.slug_name + '-in-' + locname + '/' + 'd-' + base64_encode(resp.id) + '-' + base64_encode(locid) : resp.slug_name + '/' + base64_encode(resp.id) )}}>
                        <img src={process.env.REACT_APP_AWS_S3_URL + '/' + resp.image} alt={resp.name} />
                        <p>{resp.name}</p>
                      </button>
                    </div>
                  </Grid>
                )
              }) 
              :
              <>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2} >
                  <div className='vechicle-grid aj-skelton'></div>
                </Grid>
          
              </>
            }
            
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default HomeVechicle