import { AUTH_CONTEXT } from '../context';

const INITIAL_STATE = {
  isSessionValid: false,
  user: {
    name: "",
  },
  token: {
    access_token: "",
    expiry_in: 0
  },
  token_expires_at: null
}

export const AuthReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_CONTEXT.LOGIN:
      return {
        ...state,
        loading: false,
        success: true,
        signInError: "",
        user: action.data.user,
        token: action.data.token,
        token_expires_at: action.data.token_expires_at,
        isSessionValid: true,
        currentAction: action.type
      }

    case AUTH_CONTEXT.LOGOUT:
      return {
        ...state,
        isSessionValid: false,
        user: {},
        token: {},
        currentAction: action.type
      }

    default:
      return state;
  }
};
