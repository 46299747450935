import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux'; 
import { actions } from '../../redux';
import { useDispatch } from 'react-redux';
import { Axios, SETTINGS_URL } from '../../utils/apiUtils';

import Navbar from './Navbar'
import Footer from './Footer'

const FAQ = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);
  
  React.useEffect(() => {
    callListAPI();
  }, []);

  const callListAPI = async (show_page) => {
    Axios.post(SETTINGS_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.settings);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  return (
    <div>
      <Navbar />
      { isSet === true ? 
        lists.map( (resp) => {
          return(
            <div className='main'>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: resp.faq }} />
              </div>
            </div>
          )
        })
      :
        <></>
      }
      <Footer />
    </div>
  )
}

export default FAQ